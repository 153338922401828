* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  background-color: #c1c1c1;
  font-family: "Dosis", sans-serif;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font-family: "Dosis", sans-serif;
  cursor: pointer;
  outline: inherit;
}

button:active,
button:focus {
  outline: none;
}

button::-moz-focus-inner {
  border: 0;
}

input {
  font-family: "Dosis", sans-serif;
}

.page {
  max-width: 1280px;
  margin: 0 auto;
  color: #eee;
  padding-bottom: 2rem;
  min-height: 92.5vh;
}

.homepage-content {
  margin: 0 auto;
  background-color: #eee;
  padding-bottom: 2rem;
  min-height: 92.5vh;
}

main {
  margin-bottom: 0;
  padding-bottom: 0;
}

.main,
.homepage {
  background-image: url("./assets/images/bg.png");
  background-attachment: fixed;
  background-color: #c1c1c1;
}

.main header {
  text-align: center;
  font-size: 3rem;
  padding: 2rem;
}

.main .page,
.main .homepage {
  background-color: #eee;
  color: #333;
}

.main .page .loading {
  text-align: center;
  padding-top: 10rem;
}

.under-construction {
  background: url("./assets/images/uc.png");
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.5px;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.lightbox img {
  max-width: 90%;
  max-height: 90%;
}

.lightbox-trigger {
  cursor: pointer;
  max-width: 50%;
  height: auto;
}

.main h1 {
  margin: 0;
  font-weight: normal;
  font-size: 3rem;
  text-align: center;
}

.main h1:not(.under-construction) {
  text-shadow: 0 2px 1px #fff;
}

.main p {
  text-align: justify;
  opacity: 0.9;
  margin: 0;
}

.main .homepage-content {
  background-image: url("../public/img/desktop.webp");
  background-size: 100% 100%;
  min-height: calc(100vh - 5rem);
}

.main .overview p,
.main .homepage-content p {
  width: 60%;
  position: relative;
  background: linear-gradient(to bottom, #ffffff40, #eeeeee40);
  margin: 0 auto;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(172, 161, 139, 0.3);
  text-align: center;
}

.main .overview .quote {
  position: relative;
  top: 3.6rem;
  left: 10%;
}

.main .overview p,
.main .home-overview p {
  text-align: center;
  color: #646050;
  text-shadow: 0 1px 0 #fff;
}

.main .home-overview p {
  max-width: 720px;
  width: 80%;
}

.main .overview p:not(:last-child) {
  margin-bottom: 1rem;
}

.main p .title {
  color: #000;
}

.main .overview img {
  margin-top: 1rem;
}

.main p img {
  vertical-align: bottom;
}

.main article:not(.overview) p {
  margin-bottom: 0.5rem;
}

.main h2 {
  font-weight: normal;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px groove black;
  text-shadow: 0 2px 0 #fff;
}

.main a {
  text-decoration: none;
  color: rgb(138, 84, 13);
}

.main a:hover,
.main a:focus {
  color: rgb(212, 147, 6);
}

.main .benefits {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: auto;
}

.main .benefits:nth-child(odd) {
  flex-direction: row-reverse;
}

.main .benefits p {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
}

.main .benefits > a {
  width: 100%;
}

.main .benefits .ani-panel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 25vh;
  min-width: 20vw;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 1.2rem 0;
}

.ani-panel.hover .glass {
  transform: scale(1.1);
  box-shadow: 0 1rem 2rem #00000030;
  backdrop-filter: blur(0.4rem);
}

.ani-panel .glass {
  transform: scale(0.8);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e6e9ec6e;
  background: linear-gradient(to bottom, #dadcdf42, #dadcdfbd);
  color: #333333b0;
  position: relative;
  backdrop-filter: blur(0.1rem);
  border-radius: 1rem;
  font-size: 2rem;
  transition: 3s;
  text-shadow: 0 2px 0 #ffffff6c, 0 -1px 0 #0000003a;
  box-shadow: 0 0 0.5rem #0000008f;
}

.ani-panel .glass div {
  padding: 1rem;
  border-radius: 1rem;
  border: 1.5px solid #00000013;
}

.ani-panel .image {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  position: absolute;
  transition: 1s ease;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes wobble {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.rotate {
  animation: rotate 2s linear;
}

.wobble {
  animation: wobble 2s ease;
}

.pulse {
  animation: pulse 2s linear;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(-15px);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.bounce {
  animation: bounce 2s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flipHorizontal {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.flipHorizontal {
  animation: flipHorizontal 2s linear;
}

.fadeInOut {
  animation: fadeInOut 1s ease-in-out;
}

.animate {
  animation-iteration-count: infinite;
}

@media screen and (max-width: 768px) {
  .main h1 {
    font-size: 3rem;
  }

  .main .benefits,
  .main .benefits:nth-child(odd) {
    flex-direction: column-reverse;
  }
}
